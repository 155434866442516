<template>
    <svg viewBox="0 0 24 24" class="">
        <path ref="icon" d="" fill="currentColor" />
    </svg>
</template>
<script setup>
import {onMounted, ref} from "vue";

const props = defineProps(['icon'])

const icon = ref()
onMounted(() => {
    icon.value.setAttribute('d', props.icon)
})
</script>
